import axios from 'axios'
import type { BookingRequest } from '@/types'

const baseURL = import.meta.env.VITE_API_URL || 'http://localhost:8000/api/v1'

const apiClient = axios.create({
	baseURL,
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	}
})

function loadCookie(name: string) {
	const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
	if (match) return match[2]
}

// See https://stackoverflow.com/questions/49967779/axios-handling-errors
apiClient.interceptors.response.use(function (response) {
    // Optional: Do something with response data
    return response;
  }, function (error) {
    // Do whatever you want with the response error here:

    // But, be SURE to return the rejected promise, so the caller still has 
    // the option of additional specialized handling at the call-site:
    return Promise.reject(error);
  });

apiClient.interceptors.request.use((config) => {
	const csrfToken = loadCookie('csrftoken')
	if (csrfToken) {
		config.headers['X-CSRFTOKEN'] = csrfToken
	}
	return config
})

export default {
	getTrainingTypes() {
		return apiClient.get('/training-types/')
	},
	getTrainings() {
		return apiClient.get('/trainings/')
	},
	getAddressAutocomplete(address: string) {
		return apiClient.get(`/address-autocomplete/?q=${address}`)
	},
	bookTraining(bookingRequest: BookingRequest) {
		return apiClient.post('/bookings/', bookingRequest)
	},
	getBooking(id: number, auth_code: string) {
		return apiClient.get(`/bookings/${id}/?auth_code=${auth_code}`)
	},
	sendInvites(bookingId: number, auth_code: string) {
		return apiClient.post(`/bookings/${bookingId}/send-invites/`, { auth_code })
	}
}
