import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ImprintView from '../views/ImprintView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import CancelationPolicyView from '../views/CancelationPolicyView.vue'
import MaintenanceLoginView from '../views/MaintenanceLoginView.vue'
import SendInvitesView from '../views/SendInvitesView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        title: 'Terminbuchung'
      }
    },
	{
	  path: '/impressum',
	  name: 'imprint',
	  component: ImprintView,
	  meta: {
		title: 'Impressum'
	  }
	},
	{
		path: '/datenschutz',
		name: 'privacy',
		component: PrivacyView,
		meta: {
			title: 'Datenschutz'
		}
	},
	{
		path: '/widerrufsbelehrung',
		name: 'cancelation-policy',
		component: CancelationPolicyView,
		meta: {
			title: 'Widerrufsbelehrung'
		}
	},
	{
		path: '/send-invites',
		name: 'send-invites',
		component: SendInvitesView,
		meta: {
			title: 'Einladungen an Teilnehmer senden'
		}
	},
    {
      path: '/maintenance',
      name: 'maintenance',
      component: MaintenanceLoginView,
      meta: {
        title: 'Wartung'
      }
    }
    /*{
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    }*/
  ],
  scrollBehavior(to, from, savedPosition) {
	return { top: 0 }
  }
})

router.beforeEach((to, from) => {
  const isMaintenance = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
  document.title = `Zierle Training - ${to.meta.title}`
  if (isMaintenance && to.name !== 'maintenance') {
    const loggedIn = localStorage.getItem('correctMaintenancePasswordEntered')
    if (loggedIn !== 'true') {
      return { name: 'maintenance' }
    }
  }
})

export default router
