<template>
	<div class="relative mt-2">
		<label :for="props.name" class="block top-0 bottom-0 absolute font-medium transition-all" :class="{
			'translate-x-2 translate-y-1.5 pointer-events-none text-base text-gray-500': isEmpty,
			'-translate-y-4 text-sm': !isEmpty,
		}">{{ props.label }} <span v-if="props.required" class="text-primary">*</span></label>
		<FontAwesomeIcon v-if="props.trailingIcon" :icon="props.trailingIcon" class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
		<input :value="modelValue" v-bind="$attrs" :id="props.name"
			@input="$event => emit('update:modelValue', ($event.target! as HTMLInputElement).value)" :type="props.type"
			@focus="() => $emit('focus')" @blur="() => $emit('blur')"
			class="mt-1 block w-full px-2 py-1 border-b-[2px] border-b-gray-300 focus:border-b-primary focus:ring-transparent focus:outline-none sm:text-sm transition-colors duration-300" />
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const emit = defineEmits(['update:modelValue', 'focus', 'blur']);

const props = defineProps({
	modelValue: {
		type: String,
		required: true
	},
	label: {
		type: String,
		required: true
	},
	type: {
		type: String,
		default: 'text'
	},
	required: {
		type: Boolean,
		default: false
	},
	name: {
		type: String,
		required: false,
	},
	trailingIcon: {
		type: Object,
		required: false
	}
});

const isEmpty = computed(() => props.modelValue === '');
</script>

<style scoped></style>