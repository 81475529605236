<template>
	<header class="top-0 p-4 sm:px-6 grid grid-cols-2 sm:grid-cols-3 gap-x-2 gap-y-2 fixed w-full z-20 bg-white shadow-md ease-in-out transition-shadow duration-300">
		<RouterLink class="z-[1] row-span-2 sm:row-span-1 flex flex-col justify-around" :to="{name:'home'}">
			<img src="@/assets/logo.png" draggable="false" class="w-[160px] sm:w-[176px] h-auto" alt="Logo" />
		</RouterLink>
		<h1 class="font-bold text-primary text-lg sm:text-2xl w-full text-right sm:text-center !z-0 m-auto">Terminbuchung</h1>
		<div class="flex flex-col justify-around !z-20 text-right w-full items-end ">
			<ButtonNav class="" @click="goBack()">
				<FontAwesomeIcon :icon="faChevronLeft" class="h-3 -translate-y-[0.1rem] mr-1" /> Zurück
			</ButtonNav>
		</div>
	</header>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import ButtonNav from '@/components/ui/ButtonNav.vue';

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
	handleScroll();
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);
})

function handleScroll() {
	const header = document.querySelector('header');
	if (!header) return;
	if (window.scrollY > 50) {
		header.classList.remove('!shadow-transparent');
	} else {
		header.classList.add('!shadow-transparent');
	}
}

function goBack() {
	history.back();
}
</script>

<style lang="scss" scoped></style>