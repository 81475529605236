<template>
	<section>
		<div class="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 flex flex-col">
			<h2 class="font-bold text-xl mb-2">
				<FontAwesomeIcon :icon="getIcon" class="mr-2" /> {{ props.name }}
			</h2>

			<div 
				class="group flex flex-col w-full mb-4"
				
			>
				<div
					class="w-full grid grid-cols-1 grid-rows-1 h-full overflow-hidden transition-[max-height] ease-in-out duration-1000"
					:class="descriptionExpanded ? 'max-h-[1600px] sm:max-h-[1000px] lg:max-h-[750px]' : 'max-h-[10em] sm:max-h-[5em] cursor-pointer'"
					@click="descriptionExpanded = true"
					>
					<p
						class="col-start-1 col-end-1 row-start-1 row-end-1 transition-colors ease-in-out duration-1000"
						:class="{
							'text-gray-700': descriptionExpanded,
							'text-gray-500': !descriptionExpanded,
						}"
						id="description"
						ref="description"
						v-html="marked.parse(trainingType?.description??'')"
					></p>
					<!--
					<div class="cursor-pointer w-full col-start-1 col-end-1 row-start-1 row-end-1"
						:class="{
							'bg-gradient-to-t from-white via-transparent via-50%': !descriptionExpanded,
						}"
					>
					</div>
					-->
				</div>
				<span class="cursor-pointer transition ease-in-out duration-300 text-gray-500 group-hover:text-primary w-full text-center"
					@click.self="toggleDescription"
				>
					{{ descriptionExpanded?'weniger':'mehr' }} anzeigen <FontAwesomeIcon :icon="faChevronDown" class="ml-2" :class="descriptionExpanded?'rotate-180':''" />
				</span>
			</div>

			<div
				class="contents"
				v-if="trainingsOfType.length !== 0"
			>
				<AppointmentItem
					v-for="training in trainingsOfType" 
					:key="training.id"
					:id="training.id"
					:date="training.date"
					:free-slots="10"
					:details-expanded="expandedDetails === training.id"
					@toggle-details="toggleDetails"
				/>

				<div
				class="flex flex-row gap-x-6 place-content-center">
					<div class="flex flex-row items-center">
						<span class="bg-primary rounded-full w-3 h-3 inline-block mr-2"></span>
						<span class="text-sm">Plätze frei</span>
					</div>

					<div class="flex flex-row items-center">
						<span class="bg-amber-400 rounded-full w-3 h-3 inline-block mr-2"></span>
						<span class="text-sm">Wenige Plätze frei</span>
					</div>

					<div class="flex flex-row items-center">
						<span class="bg-rose-600 rounded-full w-3 h-3 inline-block mr-2"></span>
						<span class="text-sm">Ausgebucht</span>
					</div>
				</div>
			</div>
			<div v-else class="w-full text-gray-500 mt-2">
				<p class="text-center">Derzeit werden keine Termine für diese Kategorie angeboten.</p>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';

import { marked } from 'marked';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faComments, faUser, faPersonChalkboard, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AppointmentItem from '@/components/AppointmentItem.vue';

import { useTrainingsStore } from '@/stores/trainingsStore.js';
import { useTrainingTypesStore } from '@/stores/trainingTypesStore.js';

const trainingsStore = useTrainingsStore();
const trainingTypesStore = useTrainingTypesStore();

const props = defineProps({
	id: {
		type: Number,
		required: true
	},
	name: {
		type: String,
		required: true
	},
	icon: {
		type: String,
		required: false
	}
});

const descriptionExpanded = ref(false);
const description = ref(null) as Ref<HTMLElement | null>;

const trainingType = computed(() => {
	return trainingTypesStore.getTrainingTypeById(props.id)
});

const getIcon = computed(() => {
	let icon_name = props.icon ?? '';
	icon_name = icon_name.replace('fa-', '');
	icon_name.toLowerCase();
	icon_name = icon_name.trim();
	switch (icon_name) {
		case 'phone':
			return faPhone;
		case 'comments':
			return faComments;
		case 'user':
			return faUser;
		default:
			return faPersonChalkboard;
	}
});

const trainingsOfType = computed(() => {
	let trainings = trainingsStore.getTrainings;
	trainings = trainings.filter((training: { training_type: number; }) => training.training_type === props.id);
	trainings.sort((a: { date: string; }, b: { date: string; }) => {
		return new Date(a.date).getTime() - new Date(b.date).getTime();
	});
	return trainings;
});

const expandedDetails = ref<Number | null>(null);

function toggleDetails(id: Number) {
	expandedDetails.value = expandedDetails.value === id ? null : id;
}

function toggleDescription() {
	if (!description.value) return
	if (!descriptionExpanded.value) {
		descriptionExpanded.value = true;
	} else {
		descriptionExpanded.value = false;
		setTimeout(() => {
			description.value.classList.add('line-clamp-3');
		}, 1000);
	}
}

onMounted(() => {
	if (description.value) {
		description.value.classList.add('line-clamp-3');
	}
})

watch(() => descriptionExpanded.value, () => {
	if (descriptionExpanded.value) {
		description.value?.classList.remove('line-clamp-3');
	}
})
</script>

<style lang="scss">
#description {
	h2 {
		@apply font-medium text-xl mt-2 mb-1;
	}

	h3 {
		@apply font-medium text-lg mt-2 mb-1;
	}

	p {
		hyphens: auto;
		text-align: left;
		word-wrap: break-word;
		text-justify: none;
		margin-bottom: 1em;
	}
}
</style>