<template>
	<div class="flex flex-col justify-between h-screen">
		<NavBar />
		<div id="wrapper" class="pt-24 grow">
			<RouterView />
		</div>
		<FooterBar />
	</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'

import { useTrainingTypesStore } from '@/stores/trainingTypesStore.js'
import { useTrainingsStore } from '@/stores/trainingsStore.js'

onMounted(() => {
	const trainingTypesStore = useTrainingTypesStore()
	trainingTypesStore.fetchTrainingTypes()

	const trainingsStore = useTrainingsStore()
	trainingsStore.fetchTrainings()
})
</script>

<style scoped>
#wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
}
</style>
