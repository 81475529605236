import { defineStore } from "pinia"

import api from '@/api'

export const useTrainingsStore = defineStore('trainings', {
	state: () => ({
		trainings: []
	}),
	getters: {
		getTrainings() {
			return this.trainings
		},
		getTrainingById: (state) => (id) => {
			return state.trainings.find(training => training.id === id)
		}
	},
	actions: {
		fetchTrainings() {
			api.getTrainings()
				.then(response => {
					this.trainings = response.data
				})
		},
	}
})
