<template>
	<div class="m-auto flex md:flex-row flex-col justify-around items-center">
		<img src="@/assets/build_website.svg" class="p-8 md:w-1/3 w-1/2" alt="Building a website" />
		<div class="flex flex-col md:p-0 p-8">
			<h1 class="text-3xl font-bold mb-2">
				Wartungsmodus
			</h1>
			<p class="mb-8">
				Derzeit wird an der Seite gearbeitet. Bitte logge Dich ein, um die Seite zu betreten.
			</p>
			<InputField @keyup.enter="login" v-model="password" class="mb-3" label="Passwort" type="password" />
			<ButtonPrimary class="mt-8 text-center"
			@click="login"
			>
				Einloggen
			</ButtonPrimary>
		</div>
	</div>
</template>

<script setup>
import {ref} from 'vue';
import { useRouter } from 'vue-router';

import InputField from '@/components/ui/InputField.vue';
import ButtonPrimary from '@/components/ui/ButtonPrimary.vue';

const router = useRouter();

const password = ref('');

function login() {
	const expectedPassword = import.meta.env.VITE_MAINTENANCE_MODE_PASSWORD;
	if (password.value === expectedPassword) {
		localStorage.setItem('correctMaintenancePasswordEntered', 'true');
		router.push('/');
	} else {
		alert('Falsches Passwort');
	}
}
</script>

<style lang="scss" scoped></style>
