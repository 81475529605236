<template>
	<a
		class="select-none border-[3px] border-transparent p-2 px-4 uppercase transition-colors duration-300 hover:bg-primary hover:text-white cursor-pointer text-center">
		{{ props.text }}
	</a>
</template>

<script setup lang="ts">
const props = defineProps({
	text: {
		type: String,
		required: true
	},
});
</script>

<style lang="scss" scoped></style>