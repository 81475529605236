<template>
	<Teleport to="body">
		<div v-show="props.isVisible" @click.self="emit('close')" tabindex="0" @keyup.escape="emit('close')"
			class="w-vw h-vh transition-all fixed flex flex-col justify-end md:justify-around items-end md:items-center top-0 left-0 right-0 bottom-0 bg-gray-700/70 z-40 cursor-pointer"
			ref="backdrop">
			<div
				ref="box"
				class="relative w-full md:w-3/4 lg:w-2/3 xl:w-2/3 max-w-[900px] bg-white rounded-t-xl md:rounded cursor-auto shadow-lg transition-all ease-in-out">
				<FontAwesomeIcon @click="emit('close')" :icon="faTimes"
					class="absolute top-4 right-4 text-gray-300 p-2 w-8 h-8 md:w-5 md:h-5 cursor-pointer" />
				<h2 class="m-6 text-2xl font-bold mb-4 text-primary">
					<slot name="title">
						Modal Titel
					</slot>
				</h2>
				<div class="overflow-scroll max-h-[60vh] sm:max-h-[70vh] mx-6">
					<slot />
				</div>
				<div class="p-6 mt-8 bg-gray-100 md:rounded-b">
					<slot name="actions">
						<div class="flex justify-between mt-8">
							<ButtonGhost :text="props.cancelText" @click="emit('close')" />
							<ButtonPrimary @click="emit('submit')">
								{{ props.submitText }}
							</ButtonPrimary>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ButtonGhost from './ButtonGhost.vue';
import ButtonPrimary from './ButtonPrimary.vue';

import { onMounted, ref, watch } from 'vue';


const emit = defineEmits(['close', 'submit']);

const props = defineProps({
	isVisible: {
		type: Boolean,
		default: false
	},
	submitText: {
		type: String,
		default: 'Ok'
	},
	cancelText: {
		type: String,
		default: 'Abbrechen'
	}
});

const box = ref<HTMLElement | null>(null);
const backdrop = ref<HTMLElement | null>(null);

const boxClasses = [
	"sm:scale-90",
	"translate-y-5",
	"sm:translate-y-0",
]

function addClassesToBox() {
	if (box.value) {
		for (const boxClass of boxClasses) {
			box.value.classList.add(boxClass)
		}
	}
}

function removeClassesFromBox() {
	if (box.value) {
		for (const boxClass of boxClasses) {
			box.value.classList.remove(boxClass)
		}
	}
}

onMounted(() => {
	if (box.value) {
		addClassesToBox()
	}
	if (backdrop.value) {
		backdrop.value.classList.add('opacity-0')
	}
})

watch(() => props.isVisible, (isVisible) => {
	if (!isVisible) {
		addClassesToBox()
		if(backdrop.value) {
			backdrop.value.classList.add('opacity-0')
		}
		return
	}
	setTimeout(() => {
		if(!props.isVisible) return
		removeClassesFromBox()
		if(backdrop.value) {
			backdrop.value.classList.remove('opacity-0')
		}
	}, 10)
});

</script>

<style scoped></style>