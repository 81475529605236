<template>
	<a class="group cursor-pointer  uppercase flex flex-col text-[#5a5c5b] translate-y-[.2rem]">
		<span><slot /></span>
		<span
			class="w-full h-[3px] bg-primary translate-y-[.6rem] opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300 ease-in-out"></span>
	</a>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped></style>