import { defineStore } from "pinia"

import api from '@/api'

export const useTrainingTypesStore = defineStore('trainingTypes', {
	state: () => ({
		trainingTypes: []
	}),
	getters: {
		getTrainingTypes() {
			return this.trainingTypes
		},
		getTrainingTypeById: (state) => (id) => {
			return state.trainingTypes.find(trainingType => trainingType.id === id)
		}
	},
	actions: {
		fetchTrainingTypes() {
			api.getTrainingTypes()
				.then(response => {
					this.trainingTypes = response.data
				})
		}
	}
})
