<template>
	<main>
		<TrainingType v-for="type in trainingTypesStore.getTrainingTypes" :key="type.id" :id="type.id" :name="type.name"
			:icon="type.fa_icon" />
	</main>
</template>

<script setup lang="ts">
import { useTrainingTypesStore } from '@/stores/trainingTypesStore.js';
import TrainingType from '@/components/TrainingType.vue';

const trainingTypesStore = useTrainingTypesStore();
</script>