<template>
	<div class="my-4">
		<div class="relative flex flex-col sm:flex-row items-center w-full py-4 gap-y-4 gap-x-6 md:gap-x-12 border border-l-[1.5rem] border-primary hover:shadow-lg transition-shadow duration-300 z-[1]"
			:class="{
				'shadow-lg': props.detailsExpanded,
				'border-primary': isAvailable && !isFewFreeSlots,
				'!border-amber-400': isAvailable && isFewFreeSlots,
				'border-rose-600': !isAvailable
			}">
			<div></div>
			<div class="font-medium whitespace-nowrap">{{ dateFormatted }}</div>
			<div v-if="isAvailable && isFewFreeSlots" class="flex flex-col justify-around">
				<span class="whitespace-nowrap rounded-full bg-secondary-100 px-2.5 py-0.5 text-sm text-secondary">
					{{ training.free_slots }} {{ training.free_slots === 1 ? 'Platz' : 'Plätze' }} frei
				</span>
			</div>
			<div v-if="!isAvailable" class="flex flex-col justify-around">
				<span class="whitespace-nowrap rounded-full bg-rose-100 px-2.5 py-0.5 text-sm text-rose-700">
					ausgebucht
				</span>
			</div>
			<div class="grow"></div>
			<div class="flex flex-col justify-around sm:pr-4">
				<a class="cursor-pointer whitespace-nowrap text-primary-600 font-medium select-none hover:text-primary-500 transition-colors duration-300"
					@click="emit('toggleDetails', props.id)">
					Details
					<FontAwesomeIcon :icon="faChevronDown" class="ml-1"
						:class="{ 'rotate-180': props.detailsExpanded }" />
				</a>
			</div>
			<div class="flex flex-col justify-around sm:pr-4">
				<ButtonPrimary @click="isAvailable ? isModalVisible = true : null" :deactivated="!isAvailable"
					class="inline-block">
					Jetzt buchen
				</ButtonPrimary>
			</div>
		</div>
		<div class="block overflow-hidden transition-[max-height] duration-1000 z-0" :class="{
			'max-h-0': !props.detailsExpanded,
			'max-h-[30em] md:max-h-[12.5rem]': props.detailsExpanded
		}">

			<div class="p-4 bg-gray-100 text-gray-600 grid md:grid-cols-3 gap-4">
				<div class="bg-white p-4">
					<h2 class="font-medium text-xl mb-2">Tage &amp; Uhrzeit</h2>
					<p>{{ dateFormattedLong }}</p>
					<p><span class="whitespace-nowrap">{{ timeSpanFormatted.start }} Uhr</span> - <span
							class="whitespace-nowrap">{{ timeSpanFormatted.end }} Uhr</span></p>
				</div>
				<div class="bg-white p-4">
					<h2 class="font-medium text-xl mb-2">Teilnehmer</h2>
					<p>{{ participantsRangeFormatted }}</p>
					<p>Freie Plätze: {{ training.free_slots }} <span class="text-sm">/{{ training.max_participants
							}}</span></p>
					<p class="text-xs mt-1">Das Training findet nur bei Erreichen der Mindestteilnehmerzahl statt.</p>
				</div>
				<div class="bg-white p-4">
					<h2 class="font-medium text-2xl">€ {{ priceInclVatFormatted }} <span
							class="font-normal text-xl whitespace-nowrap">inkl. Mwst.</span></h2>
					<p class="text-gray-400">€ {{ priceExclVatFormatted }} <span class="whitespace-nowrap">zzgl.
							Mwst.</span></p>
				</div>
			</div>
		</div>

		<ModalBox :is-visible="isModalVisible" @close="isModalVisible = false" @submit="submitBooking"
			submit-text="Termin verbindlich buchen" cancel-text="Abbrechen">
			<template #title>
				Termin für den {{ dateFormatted }} buchen
				<span class="block text-xl text-gray-500">{{ trainingType.name }}</span>
			</template>

			<template #default>
				<div v-if="step !== 4" class="mb-4">
					<h2 class="sr-only">Steps</h2>
					<div
						class="relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
						<ol class="relative z-10 flex justify-between text-sm font-medium text-gray-500">
							<li class="cursor-pointer flex items-center gap-2 bg-white p-2 pl-0" @click="trySetStep(1)">
								<span class="size-6 rounded-full text-center text-[10px]/6 font-bold" :class="{
									'bg-primary text-white': step === 1,
									'bg-gray-100': step !== 1
								}">
									1
								</span>

								<span class="hidden sm:block"> Wer nimmt teil? </span>
							</li>

							<li class="cursor-pointer flex items-center gap-2 bg-white p-2" @click="trySetStep(2)">
								<span class="size-6 rounded-full text-center text-[10px]/6 font-bold" :class="{
									'bg-primary text-white': step === 2,
									'bg-gray-100': step !== 2
								}">
									2
								</span>

								<span class="hidden sm:block"> Wer bezahlt? </span>
							</li>

							<li class="cursor-pointer flex items-center gap-2 bg-white p-2 pr-0" @click="trySetStep(3)">
								<span class="size-6 rounded-full text-center text-[10px]/6 font-bold" :class="{
									'bg-primary text-white': step === 3,
									'bg-gray-100': step !== 3
								}">
									3
								</span>

								<span class="hidden sm:block"> Passt alles? </span>
							</li>
						</ol>
					</div>
				</div>

				<div v-if="error" class="bg-rose-100 border border-rose-400 text-rose-700 p-4 mb-4 ">
					{{ error }}
				</div>

				<div v-if="step === 1">
					<div class="relative">
						<div v-show="participants.length < training.free_slots" class="grid grid-cols-2 gap-4 gap-y-6">
							<InputField @keyup.enter="addParticipant" name="firstName" v-model="newParticipantFirstName"
								label="Vorname" required />
							<InputField @keyup.enter="addParticipant" name="lastName" v-model="newParticipantLastName"
								label="Nachname" required />
							<InputField @keyup.enter="addParticipant" name="email" v-model="newParticipantEmail"
								class="col-span-2" label="E-Mail-Adresse" required />
						</div>
						<div class="w-full flex flex-row items-center gap-x-2 mt-8">
							<FontAwesomeIcon :icon="faCircleInfo" class="text-primary w-4 h-4" />
							<p class="text-gray-600">{{ participants.length === 1 ? 'Der' : 'Die' }} Teilnehmer {{
								participants.length === 1 ? 'erhält' : 'erhalten' }} die Logindaten für das Seminar.</p>
						</div>
						<span class="flex flex-col lg:flex-row items-center w-full mb-2 mt-10"
							v-show="training.free_slots > 1">
							<span v-show="participants.length < training.free_slots - 1" class="shrink-0 lg:pr-6">
								<ButtonPrimary @click="addParticipant" :deactivated="!isNewParticipantValid">
									<FontAwesomeIcon :icon="faUser" class="mr-2" /> Weiteren Teilnehmer hinzufügen
								</ButtonPrimary>
							</span>
							<span v-show="participants.length < training.free_slots - 1"
								class="h-0.5 py-2 lg:p-0 flex-1 bg-gray-100"></span>
							<span v-show="participants.length < training.free_slots - 1"
								class="py-2 lg:py-0 shrink-0 px-3 text-gray-300">oder</span>
							<span v-show="participants.length < training.free_slots - 1"
								class="py-2 lg:p-0 h-0.5 flex-1 bg-gray-100"></span>
							<span v-show="participants.length >= training.free_slots - 1" class="grow"></span>
							<span class="shrink-0 lg:pl-6">
								<ButtonPrimary @click="addParticipantAndNextStep" :deactivated="!canProceedStep">
									Nächster Schritt
									<FontAwesomeIcon :icon="faChevronDown" class="mr-2 -rotate-90" />
								</ButtonPrimary>
							</span>
						</span>
					</div>
					<h3 v-show="participants.length !== 0" class="my-4 font-semibold text-gray-600">
						Hinzugefügte Teilnehmer <span class="text-xs">({{ participants.length }}/{{ training.free_slots
							}})</span>
					</h3>
					<table class="w-full grid grid-cols-12 text-gray-500 gap-y-3">
						<tr v-for="i in Array.from(Array(participants.length).keys())" :key="i" class="contents">
							<td class="border col-span-3 rounded-l-md">
								<input type="text"
									class="w-full rounded-l-md p-1 px-2 bg-gray-100 focus:bg-white focus:outline focus:outline-2 focus:outline-primary"
									v-model="participants[i].first_name" />
							</td>
							<td class="border col-span-3">
								<input type="text"
									class="w-full p-1 px-2 bg-gray-100 focus:bg-white focus:outline focus:outline-2 focus:outline-primary"
									v-model="participants[i].last_name" />
							</td>
							<td class="border col-span-5 rounded-r-md">
								<input type="text"
									class="w-full rounded-r-md p-1 px-2 bg-gray-100 focus:bg-white focus:outline focus:outline-2 focus:outline-primary"
									v-model="participants[i].email" />
							</td>
							<td class="flex items-center justify-around">
								<button type="button"
									class="transition duration-300 flex items-center justify-around rounded-md h-full aspect-square text-rose-400 hover:text-white hover:bg-rose-400"
									@click="participants.splice(i, 1)">
									<FontAwesomeIcon :icon="faTrash" class="w-4 h-4" />
								</button>
							</td>
						</tr>
					</table>

				</div>
				<div v-if="step === 2">
					<div class="grid grid-cols-2 gap-4 gap-y-6">
						<InputField name="company" v-model="invoiceDetails.company_name" label="Firma"
							class="col-span-2" />
						<InputField name="firstName" v-model="invoiceDetails.first_name" label="Vorname" required />
						<InputField name="lastName" v-model="invoiceDetails.last_name" label="Nachname" required />
						<InputField name="email" v-model="invoiceDetails.email" label="E-Mail-Adresse" required />
						<InputField name="phone" v-model="invoiceDetails.phone" label="Telefon" required />
						<div class="col-span-2 w-full relative">
							<InputField name="street" id="invoiceStreet" v-model="invoiceDetails.street"
								label="Straße und Hausnummer" required @update:model-value="autocompleteAddress"
								@focus="isStreetSelected = true" @blur="hideAddressAutocompleteResults"
								:trailing-icon="faSearch" />
							<div v-if="addressAutoCompleteResults.length > 0 && isStreetSelected"
								class="absolute z-10 w-full bg-white border max-h-[12em] overflow-y-scroll shadow">
								<ul>
									<li v-for="(result, index) in addressAutoCompleteResults" :key="index"
										@click="fillInvoiceAddress(result)"
										class="cursor-pointer p-2 hover:bg-gray-100 text-gray-600">
										{{ result.formatted }}
									</li>
								</ul>
							</div>
						</div>
						<InputField name="zip" v-model="invoiceDetails.zip_code" label="PLZ" required />
						<InputField name="city" v-model="invoiceDetails.city" label="Ort" required />
						<InputField name="country" v-model="invoiceDetails.country" class="col-span-2" label="Land"
							required />
					</div>
				</div>
				<div v-if="step === 3">
					<!--
					<h3 class="w-full text-center text-gray-500 mb-6 font-semibold text-xl">Passt alles?</h3>
					-->
					<div class="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-6">
						<div>
							<h3 class="mb-4 font-semibold text-gray-600">
								<FontAwesomeIcon :icon="faUser" class="mr-2" /> Teilnehmer <span class="text-xs">({{
									participants.length }})</span>
							</h3>
							<div class="relative max-h-[11em] overflow-hidden" :class="{
								'max-h-[11em]': !isParticipantsListExpanded,
								'max-h-[9999px]': isParticipantsListExpanded
							}">
								<div v-for="(participant, index) in participants" :key="index"
									class="text-gray-600 mb-2">
									<p>{{ participant.first_name }} {{ participant.last_name }}</p>
									<p class="text-sm text-gray-400">{{ participant.email }}</p>
								</div>
								<div v-show="participants.length > 3"
									@click="isParticipantsListExpanded = !isParticipantsListExpanded"
									class="cursor-pointer bg-gradient-to-b from-transparent to-white hover:to-gray-100 w-full bottom-0 left-0 flex flex-col items-center justify-end"
									:class="{
										'h-20 absolute': !isParticipantsListExpanded,
										'bg-gradient-to-t !to-transparent': isParticipantsListExpanded
									}">
									<FontAwesomeIcon :icon="faChevronDown" class="text-gray-300 w-6 h-6 mb-2" :class="{
										'rotate-180': isParticipantsListExpanded
									}" />
								</div>
							</div>
						</div>

						<div>
							<h3 class="mb-4 font-semibold text-gray-600">
								<FontAwesomeIcon :icon="faFileInvoice" class="mr-2" /> Rechnungsdetails
							</h3>
							<div class="text-gray-700">
								<p>{{ invoiceDetails.first_name }} {{ invoiceDetails.last_name }}</p>
								<p class="flex flex-row items-center">
									<FontAwesomeIcon class="mr-2 text-gray-400 w-3 h-3" :icon="faEnvelope" />
									<span>{{ invoiceDetails.email }}</span>
								</p>
								<p class="flex flex-row items-center">
									<FontAwesomeIcon class="text-gray-400 mr-2 w-3 h-3" :icon="faPhone" />
									<span>{{ invoiceDetails.phone }}</span>
								</p>
								<p class="mt-3">{{ invoiceDetails.company_name }}</p>
								<p>{{ invoiceDetails.street }}</p>
								<p>{{ invoiceDetails.zip_code }} {{ invoiceDetails.city }}</p>
								<p>{{ invoiceDetails.country }}</p>
							</div>
						</div>
					</div>

					<InputCheckbox v-model="agbCheckbox" class="mt-8 mb-2" required>
						Ich habe die <RouterLink :to="{ name: 'privacy' }" target="_blank" class="text-primary">
							Datenschutzerklärung</RouterLink> gelesen und
							akzeptiere sie.
					</InputCheckbox>
					<InputCheckbox v-model="cancellationCheckbox" class="mb-6" required>
						Ich habe die <RouterLink :to="{ name: 'cancelation-policy' }" target="_blank"
							class="text-primary">Widerrufsbelehrung</RouterLink> gelesen und akzeptiere sie.
					</InputCheckbox>

					<vue-hcaptcha v-if="captchaRequired" ref="captchaComponent"
						class="w-full flex flex-row items-center justify-center"
						sitekey="951174db-4959-4975-b7e3-c8314a19c066" language="de" @expired="onCaptchaExpired"
						@verify="onChallengeCompleted">
					</vue-hcaptcha>
				</div>
				<div v-if="step === 4">
					<div class="flex flex-col items-center justify-center py-12">
						<FontAwesomeIcon :icon="faThumbsUp" class="text-primary w-8 h-8" />
						<h3 class="font-semibold text-gray-600 mt-4">Vielen Dank für Ihre Buchung!</h3>
						<p class="text-gray-600 mt-4">Frau Zierle-Kohlmorgen wird sich zeitnah mit Ihnen in Verbindung
							setzen.</p>
					</div>
				</div>
				<p v-if="step !== 4" class="text-gray-600 mt-4">
					Bitte füllen Sie alle mit <span class="text-primary">*</span> gekennzeichneten Felder aus.
				</p>
			</template>

			<template #actions>
				<div class="flex flex-col sm:flex-row gap-y-3 items-center sm:justify-between">
					<div class="flex flex-row w-full sm:w-auto sm:block">
						<ButtonGhost v-show="step !== 4" text="Abbrechen" @click="isModalVisible = false"
							class="grow" />
					</div>
					<div class="flex flex-row w-full sm:w-auto sm:block">
						<ButtonGhost v-show="step > 1 && step < 4" text="Zurück" @click="step -= 1" class="mr-2" />
						<ButtonPrimary v-show="step < 3" :deactivated="!canProceedStep" @click="tryIncrementStep"
							class="grow">Weiter
						</ButtonPrimary>
						<ButtonPrimary v-show="step === 3" @click="submitBooking"
							:deactivated="isSubmitting || !canProceedStep" class="relative grow">
							<span :class="{
								'opacity-0': isSubmitting
							}">Jetzt buchen</span>
							<span class="absolute top-2 left-0 w-full text-center" role="status" :class="{
								'opacity-100': isSubmitting,
								'opacity-0': !isSubmitting
							}">
								<FontAwesomeIcon :icon="faCircleNotch" class="animate-spin text-gray-400"
									aria-hidden="true" />
								<span class="sr-only">Loading...</span>
							</span>
						</ButtonPrimary>
						<ButtonPrimary v-show="step === 4" @click="isModalVisible = false; step = 1" class="grow">Zu den
							Trainings</ButtonPrimary>
					</div>
				</div>
			</template>
		</ModalBox>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, type Ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faChevronDown,
	faCircleInfo,
	faTrash,
	faUser,
	faFileInvoice,
	faEnvelope,
	faPhone,
	faCircleNotch,
	faThumbsUp,
	faSearch,
} from '@fortawesome/free-solid-svg-icons';

import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

import debounce from 'debounce';

import ButtonPrimary from '@/components/ui/ButtonPrimary.vue';
import ButtonGhost from '@/components/ui/ButtonGhost.vue';
import ModalBox from '@/components/ui/ModalBox.vue';
import InputField from '@/components/ui/InputField.vue';
import InputCheckbox from '@/components/ui/InputCheckbox.vue';

import { useTrainingsStore } from '@/stores/trainingsStore.js';
import { useTrainingTypesStore } from '@/stores/trainingTypesStore.js';

import api from '@/api';
import type { BookingRequest } from '@/types';

const emit = defineEmits(['toggleDetails']);

const FEW_FREE_SLOTS = 3;

const props = defineProps({
	id: {
		type: Number,
		required: true
	},
	detailsExpanded: {
		type: Boolean,
		default: false
	},
});

const captchaRequired = import.meta.env.VITE_REQUIRE_CAPTCHA === 'true';
const captchaComponent = ref(null) as Ref<VueHcaptcha | null>;
const captchaToken = ref('');

const error = ref('');

const agbCheckbox = ref(false);
const cancellationCheckbox = ref(false);

function onCaptchaExpired() {
	console.log('Captcha expired. Resetting...');
	captchaToken.value = '';
	captchaComponent.value?.reset();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onChallengeCompleted(token: string, eKey: string) {
	captchaToken.value = token;
}

const trainingTypesStore = useTrainingTypesStore();
const trainingsStore = useTrainingsStore();

const step = ref(1);
const isSubmitting = ref(false);

const participants = ref([]) as Ref<{ first_name: string; last_name: string; email: string }[]>;

const newParticipantEmail = ref('');
const newParticipantFirstName = ref('');
const newParticipantLastName = ref('');

const invoiceDetails = ref({
	company_name: '',
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	street: '',
	zip_code: '',
	city: '',
	country: '',
});

const isParticipantsListExpanded = ref(false);

const addressAutoCompleteResults = ref([]) as Ref<{ street: string; housenumber: string; zip_code: string; city: string; country: string; formatted: string }[]>;

function isEmail(value: string) {
	// check email has form: smth@smth.smth
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(value);
}

function isPhone(value: string) {
	// remove all non-digit characters
	value = value.replace(/\D/g, '');
	const phoneRegex = /^\d+$/;
	return phoneRegex.test(value);
}

const isNewParticipantValid = computed(() => {
	if (newParticipantEmail.value === '' || newParticipantFirstName.value === '' || newParticipantLastName.value === '') {
		return false;
	}
	return isEmail(newParticipantEmail.value);
});

function validateStep(stepNumber: number) {
	if (stepNumber === 1) {
		return participants.value.length > 0 || isNewParticipantValid.value;
	} else if (stepNumber === 2) {
		const requiredFields = ['first_name', 'last_name', 'email', 'phone', 'street', 'zip_code', 'city', 'country'];
		for (const field of requiredFields) {
			if (invoiceDetails.value[field as keyof typeof invoiceDetails.value] === '') {
				return false;
			}
		}
		if (!isEmail(invoiceDetails.value.email)) {
			return false;
		}
		if (!isPhone(invoiceDetails.value.phone)) {
			return false;
		}
	} else if (stepNumber === 3) {
		if (!agbCheckbox.value || !cancellationCheckbox.value) {
			return false;
		}
		if (captchaToken.value === '') {
			return false;
		}
	}
	return true;
}

const canProceedStep = computed(() => {
	return validateStep(step.value);
});

function trySetStep(setTo: number) {
	if (setTo < 1 || setTo > 3) {
		return;
	}
	for (let i = 1; i < setTo; i++) {
		if (!validateStep(i)) {
			return;
		}
	}
	step.value = setTo;
}

function tryIncrementStep() {
	if (canProceedStep.value) {
		if (step.value === 1) {
			addParticipant();
		}
		step.value += 1;
	}
}

function addParticipant() {
	if (!isNewParticipantValid.value) {
		return;
	}

	participants.value.push({
		first_name: newParticipantFirstName.value,
		last_name: newParticipantLastName.value,
		email: newParticipantEmail.value
	});

	newParticipantEmail.value = '';
	newParticipantFirstName.value = '';
	newParticipantLastName.value = '';

	(document.querySelector('input#firstName') as HTMLInputElement | null)?.focus();
}

function addParticipantAndNextStep() {
	addParticipant();
	tryIncrementStep();
}

const training = computed(() => {
	return trainingsStore.getTrainingById(props.id);
});

const trainingType = computed(() => {
	return trainingTypesStore.getTrainingTypeById(training.value.training_type);
});

const dateFormatted = computed(() => {
	const date = new Date(training.value.date);
	return date.toLocaleDateString('de-DE', {
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});
});

const dateFormattedLong = computed(() => {
	const date = new Date(training.value.date);
	return date.toLocaleDateString('de-DE', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long'
	});
});

const timeSpanFormatted = computed(() => {
	const start = training.value.start_time.split(':');
	const end = training.value.end_time.split(':');
	return {
		start: `${start[0]}:${start[1]}`,
		end: `${end[0]}:${end[1]}`

	};
});

const participantsRangeFormatted = computed(() => {
	const min = training.value.min_participants;
	const max = training.value.max_participants;
	if (max === 1) {
		return `${max} Person`;
	} else if (min === max) {
		return `${min} Personen`;
	}
	return `${min} bis ${max} Personen`;
});

function formatPrice(price: number) {
	let priceString = price.toFixed(2).replace('.', ',');
	priceString = priceString.replace(',00', ',-');
	return priceString;
}

const priceInclVatFormatted = computed(() => {
	return formatPrice(training.value.price);
});

const priceExclVatFormatted = computed(() => {
	return formatPrice(training.value.price / 1.19);
});

const isModalVisible = ref(false);

const isStreetSelected = ref(false);

const isAvailable = training.value.free_slots > 0;
const isFewFreeSlots = training.value.free_slots <= FEW_FREE_SLOTS;

const hideAddressAutocompleteResults = debounce(() => { isStreetSelected.value = false }, 100)

const autocompleteAddress = debounce(async () => {
	let { status, data } = await api.getAddressAutocomplete(invoiceDetails.value.street);

	if (status === 200 && data.results) {
		addressAutoCompleteResults.value = [];
		let results = data.results;

		for (let result of results) {
			addressAutoCompleteResults.value.push({
				street: result.street ?? '',
				housenumber: result.housenumber ?? '',
				zip_code: result.postcode ?? '',
				city: result.city ?? '',
				country: result.country ?? '',
				formatted: `${result.street ?? ''}${result.housenumber ? ' ' : ''}${result.housenumber ?? ''}, ${result.postcode ?? ''} ${result.city ?? ''}, ${result.country ?? ''}`
			});
		}
	} else {
		addressAutoCompleteResults.value = [];
	}
}, 300)

function fillInvoiceAddress(result: { street: string; housenumber: string; zip_code: string; city: string; country: string; formatted: string }) {
	invoiceDetails.value.street = `${result.street}${result.housenumber !== '' ? ' ' : ''}${result.housenumber}`;
	invoiceDetails.value.zip_code = result.zip_code;
	invoiceDetails.value.city = result.city;
	invoiceDetails.value.country = result.country;
}

async function submitBooking() {
	error.value = '';
	if (isSubmitting.value || !canProceedStep.value) {
		return;
	}

	isSubmitting.value = true;
	const timeSend = new Date().getTime();

	let bookingData: BookingRequest = {
		participants: participants.value,
		invoice: invoiceDetails.value,
		captchaToken: captchaToken.value,
		training: training.value.id,
	}

	const response = await api.bookTraining(bookingData).catch((err) => {
		console.error(err);
		error.value = err.request.response;
	});

	if (!response) {
		isSubmitting.value = false;
		return;
	}

	const { status } = response;

	const timeReceive = new Date().getTime();
	const timeDiff = timeReceive - timeSend;
	if (timeDiff < 1000) {
		// wait at least 1 second before evaluating the response
		await new Promise(resolve => setTimeout(resolve, 1000 - timeDiff));
	}

	if (status === 201) {
		// reset form
		participants.value = [];
		invoiceDetails.value = {
			company_name: '',
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			street: '',
			zip_code: '',
			city: '',
			country: '',
		};
		agbCheckbox.value = false;
		cancellationCheckbox.value = false;
		captchaToken.value = '';
		step.value = 4;

		trainingsStore.fetchTrainings();
	}

	isSubmitting.value = false;
}
</script>
