<template>
	<a class="select-none border-[3px] p-2 px-4 whitespace-nowrap uppercase transition-colors duration-300 text-center" :class="{
		'border-primary hover:bg-primary bg-white hover:text-white cursor-pointer': !props.deactivated,
		'border-gray-300 bg-white cursor-default text-gray-500': props.deactivated
	}">
		<slot />
	</a>
</template>

<script setup lang="ts">
const props = defineProps({
	deactivated: {
		type: Boolean,
		default: false
	}
});
</script>

<style lang="scss" scoped></style>