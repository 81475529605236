<template>
	<div>
		<label :for="props.id" class="group flex items-center relative cursor-pointer select-none">
			<input type="checkbox" :id="props.id" v-model="value" @change="emitUpdate"
				class="form-checkbox opacity-0 cursor-pointer w-0 h-0" :required="props.required" />
			<span class="checkmark m-0 p-0 min-w-5 max-w-5 w-5 min-h-5 max-h-5 h-5 border-[3px] border-primary">
				<FontAwesomeIcon class="m-auto ml-[0.0625rem] text-white text-sm hidden" :icon="faCheck" />
			</span>
			<span class="ml-2 text-gray-600">
				<slot />
				<span v-if="props.required" class="text-primary">*</span>
			</span>
		</label>
	</div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ref } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true
	},
	id: {
		type: String,
		required: false
	},
	required: {
		type: Boolean,
		default: false
	}
});

const value = ref(props.modelValue);

function emitUpdate(event: Event) {
	emit('update:modelValue', ((event.target!) as HTMLInputElement).checked);
}
</script>

<style scoped>
input:checked+.checkmark {
	@apply bg-primary;
}

input:checked+.checkmark>svg {
	@apply !block;
}
</style>