<template>
	<main class="w-full sm:max-w-[900px] p-6 text-justify">
		<h1 class="font-bold text-3xl mb-6">Widerrufsbelehrung</h1>
		<p>Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
			ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.</p>

		<h2 class="font-bold text-xl mt-8 mb-3">Widerrufsrecht</h2>
		<p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
			Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben,
			müssen Sie uns (Firma Antje Zierle-Kohlmorgen - Kommunikations- & Businesstraining, Rudower Straße 40,
			12557 Berlin, Telefonnummer: 030 - 91 47 13 36 , E-Mail-Adresse: info@zierle-training.de) mittels einer
			eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen
			Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das
			jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die
			Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden</p>

		<h2 class="font-bold text-xl mt-8 mb-3">Folgen des Widerrufs</h2>
		<p class="mb-8">Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
			einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
			andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
			unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
			Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
			Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
			ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte
			berechnet. Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so
			haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie
			uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
			Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
	</main>
</template>

<script setup lang="ts">
</script>

<style scoped>
</style>
