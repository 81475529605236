<template>
	<template v-if="!invitesAlreadySent">
		<div v-if="!areInvitesSent" class="mx-4 sm:m-0">
			<template v-if="error">
				<div
					class="flex flex-row items-center text-red-500 text-center w-full bg-red-100 border border-red-500 p-2 mb-4 rounded">
					<FontAwesomeIcon :icon="faExclamationTriangle" class="p-2" />
					<p class="grow">{{ error }}</p>
				</div>
			</template>
			<template v-else>
				<template v-if="booking && training && trainingType">
					<h1 class="font-bold text-xl">Einladungen an die Teilnehmer senden</h1>
					<div class="my-6">
						<h3 class="font-semibold">{{ trainingType.name }}</h3>
						<div class="grid grid-cols-2 mt-1">
							<p class="font-semibold">Datum</p>
							<p>{{ formattedDate }}</p>

							<p class="font-semibold">Uhrzeit</p>
							<p>{{ formattedTimeSpan.start }} - {{ formattedTimeSpan.end }} Uhr</p>

							<p class="font-semibold">Insgesamt gebuchte Plätze</p>
							<p>{{ training.max_participants - training.free_slots }} / {{ training.max_participants }}</p>
						</div>
					</div>
					<div class="my-6 booking">
						<h3 class="font-semibold">Buchung vom {{ bookingDateFormatted }}</h3>
						<div class="grid grid-cols-2 mt-1 gap-y-1">
							<p class="font-semibold">Name</p>
							<p>{{ booking.invoice_contact.first_name }} {{ booking.invoice_contact.last_name }}</p>

							<p class="font-semibold">Anschrift</p>
							<p><template v-if="booking.company_name">{{ booking.company_name }}<br /></template>{{
								booking.street }}<br>{{
									booking.zip_code }} {{ booking.city }}</p>

							<p class="font-semibold">E-Mail</p>
							<a :href="`mailto:${booking.invoice_contact.email}`">{{ booking.invoice_contact.email }}</a>

							<p class="font-semibold">Telefon</p>
							<a :href="`tel:${booking.invoice_contact.phone}`">{{ booking.invoice_contact.phone }}</a>

							<p class="font-semibold">Teilnehmer</p>
							<p>{{ booking.participants.length }} <span
									class="cursor-pointer px-2 ml-4 rounded bg-gray-200 text-gray-600"
									@click="isModalVisible = true">
									<FontAwesomeIcon class="mr-2 text-gray-500 text-xs" :icon="faUpRightFromSquare" />
									anzeigen
								</span></p>
						</div>
					</div>
					<div class="my-6 w-full">
						<ButtonPrimary class="w-full my-6 inline-block" text="Einladungen senden" @click="sendInvites" />
					</div>
					<div class="my-6 text-sm text-gray-500 flex flex-row items-center gap-x-2">
						<FontAwesomeIcon :icon="faCircleInfo" class="text-xl text-gray-400" />
						<div>
							<h3 class="font-semibold"> Hinweis</h3>
							<p>Es werden nur die Teilnehmer eingeladen, die zu dieser Buchung gehören.</p>
						</div>
					</div>
				</template>
				<div v-else>
					<div class="flex flex-row items-center text-center w-full">
						<FontAwesomeIcon :icon="faCircleNotch" class="p-2 text-gray-300 text-5xl mt-12 animate-spin" />
					</div>
				</div>
			</template>

			<ModalBox :isVisible="isModalVisible" @close="isModalVisible = false">
				<template #title>
					<div class="contents text-black">Teilnehmer</div>
				</template>

				<div v-if="booking" class="grid grid-cols-2 gap-2">
					<div class="font-semibold">Name</div>
					<div class="font-semibold">E-Mail</div>
					<template v-for="participant in booking.participants" :key="participant.email">
						<div>{{ participant.first_name }} {{ participant.last_name }}</div>
						<div>{{ participant.email }}</div>
					</template>
				</div>

				<template #actions>
					<div class="flex justify-center">
						<ButtonGhost @click="isModalVisible = false" text="Schließen" />
					</div>
				</template>
			</ModalBox>
		</div>
		<div v-else class="w-full md:w-[80%] flex flex-col justify-center items-center mt-8">
			<img src="@/assets/mail_send.svg" class="p-8 md:w-1/3 w-1/2" alt="" />
			<h1 class="text-3xl font-bold mb-2 pt-3 text-center">Einladungen gesendet!</h1>
			<p class="mb-8 text-center">Die Einladungen an die Teilnehmer sind für den Versand vorgemerkt und werden in Kürze abgeschickt.</p>
			<p class="text-center text-sm text-gray-500">Du kannst dieses Fenster nun schließen.</p>
		</div>
	</template>
	<template v-else>
		<div class="w-full md:w-[80%] flex flex-col justify-center items-center mt-8">
			<img src="@/assets/mail_send.svg" class="p-8 md:w-1/3 w-1/2" alt="" />
			<h1 class="text-3xl font-bold mb-2 pt-3 text-center">Einladungen bereits gesendet!</h1>
			<p class="mb-8 text-center">Die Einladungen an die Teilnehmer dieser Buchung wurden bereits versendet.</p>
			<p class="text-center text-sm text-gray-500">Du kannst dieses Fenster nun schließen.</p>
		</div>
	</template>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faExclamationTriangle,
	faCircleNotch,
	faUpRightFromSquare,
	faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

import ButtonGhost from '@/components/ui/ButtonGhost.vue';
import ButtonPrimary from '@/components/ui/ButtonPrimary.vue';
import ModalBox from '@/components/ui/ModalBox.vue';

import api from '@/api';
import type { Booking } from '@/types';
import { useTrainingTypesStore } from '@/stores/trainingTypesStore.js';
import { useTrainingsStore } from '@/stores/trainingsStore.js';
import type { AxiosError } from 'axios';

const route = useRoute();

const isModalVisible = ref(false);

const trainingTypesStore = useTrainingTypesStore();
const trainingsStore = useTrainingsStore();

const invitesAlreadySent = computed(() => {
	if (!booking.value) return false;
	return booking.value.invitations_sent;
});

const auth_code = ref('');
const booking_id = ref(0);

const booking = ref(null as Booking | null);
const training = computed(() => {
	if (!booking.value) return null;
	return trainingsStore.getTrainingById(booking.value.training);
})
const trainingType = computed(() => {
	if (!training.value) return null;
	return trainingTypesStore.getTrainingTypeById(training.value.training_type);
})

const formattedDate = computed(() => {
	if (!training.value) return '';
	let date = new Date(training.value.date);
	return date.toLocaleDateString('de-DE');
})
const formattedTimeSpan = computed(() => {
	if (!training.value) return { start: '', end: '' };
	const start = training.value.start_time.split(':');
	const end = training.value.end_time.split(':');
	return {
		start: `${start[0]}:${start[1]}`,
		end: `${end[0]}:${end[1]}`

	};
})
const bookingDateFormatted = computed(() => {
	if (!booking.value) return '';
	let date = new Date(booking.value.created_at);
	return date.toLocaleDateString('de-DE');
})

const error = ref('');
const areInvitesSent = ref(false);

async function sendInvites() {
	if (!booking.value) return;
	try {
		const response = await api.sendInvites(booking.value.id, auth_code.value);
		if (response.status === 200) {
			areInvitesSent.value = true;
		} else {
			error.value = 'Fehler beim Senden der Einladungen. Bitte informiere Marc.\n' + response.data;
		}
	} catch (e) {
		error.value = 'Fehler beim Senden der Einladungen. Bitte informiere Marc.\n' + ((e as AxiosError).response!.data! as { status: string, details: string }).details;
	}
}

onMounted(async () => {
	auth_code.value = route.query.auth_code as string;
	booking_id.value = Number.parseInt(route.query.id as string);

	if (!auth_code.value || !booking_id.value) {
		error.value = 'Ungültige URL';
		return;
	}

	try {
		const response = await api.getBooking(booking_id.value, auth_code.value);

		if (response.status === 200) {
			booking.value = response.data;
		} else {
			error.value = 'Fehler beim Laden der Buchung';
		}
	} catch (e) {
		error.value = 'Fehler beim Laden der Buchung';
	}
});
</script>

<style scoped>
.booking a {
	@apply text-primary-600 transition-colors duration-300;
}

.booking a:hover {
	@apply text-primary-500;
}
</style>